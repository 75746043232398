<template>
  <div class="card ">
    <Breadcrumb :model="items"/>
    <div class="p-mt-3" style="width: 100%">
      <DataTable ref="dt" :value="expert" v-model:selection="selectedProducts" dataKey="id"
                 :auto-layout="true"
                 :paginator="true" :rows="10" :filters="filters"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[5,10,25]"
                 currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
        <template #header>
          <div style="display: flex">
            <Button icon="pi pi-plus" label="添加专家" class="p-mr-3" @click="add()"/>
            <InputText v-model="userName" placeholder="专家姓名搜索"/>
            <Calendar class="p-ml-3" v-model="time" placeholder="入驻时间"
                      @clear-click="showExpert"
                      showButtonBar="true"
                      dateFormat="yy-mm-dd"/>
            <Dropdown class="p-ml-3" v-model="industry" :options="industries" optionLabel="name" option-value="code"
                      placeholder="专业领域" :showClear="true" :show-clear="true"/>
            <InputText class="p-ml-3" v-model="tags" placeholder="标签"></InputText>
            <Button @click="showExpert" class="p-ml-2">查询</Button>
          </div>
        </template>
        <Column field="name" header="专家" :sortable="false"></Column>
        <Column field="phone" header="电话"></Column>
        <Column field="industry" header="行业" :sortable="false">
        </Column>
        <Column field="postTime" header="入驻时间" :sortable="true">
          <template #body="slotProps">
            {{
              new Date(+new Date(slotProps.data.postTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            }}
          </template>
        </Column>
        <Column field="questionNum" header="问题解答次数" :sortable="true">
        </Column>
        <Column field="activityNum" header="活动参与次数" :sortable="true">
        </Column>
        <Column field="remuneration" header="共获取报酬" :sortable="true">
        </Column>
        <Column header="操作" :exportable="false">
          <template #body="slotProps">
            <Button class="p-button-sm p-mr-2" @click="getExpertInfo(slotProps.data)">详情</Button>
            <Button class="p-button-sm" @click="update(slotProps.data)">编辑</Button>
            <Button v-if="slotProps.data" class="p-button-danger p-button-sm p-ml-2"
                    @click="confirmDeleteProduct(slotProps.data)">
              禁用
            </Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <EditExpert v-if="display" ref="update" v-on:close="diaLogClose"></EditExpert>
  </div>
  <Toast/>
  <Dialog v-model:visible="delShow" :style="{width: '350px'}" header="确认" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
      <span v-if="product">你确认要禁用 <b>{{ product.name }}</b>?</span>
    </div>
    <template #footer>
      <Button label="否" icon="pi pi-times" class="p-button-text" @click="delShow = false"/>
      <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteUser()"/>
    </template>
  </Dialog>
  <AddExperts v-on:close="closeAdd" v-if="addExperts" ref="addExperts"></AddExperts>
  <Dialog v-model:visible="expertInfoDisplay" header="专家信息"
          style="width: 800px">
    <div>
      <img :src="expertInfo.pic">
      <h2>{{ expertInfo.name }}</h2>
      <h5>简介：{{ expertInfo.brief }}</h5>
      <div v-html="expertInfo.content"></div>
    </div>
  </Dialog>
</template>


<script>
import EditExpert from "@/views/platform/thinkTankManagement/EditExpert";
import AddExperts from "@/views/platform/thinkTankManagement/AddExperts";

export default {

  mounted() {
    this.$http.get('/dictionary/selectValue', {params: {key: 'zhuanyelingyu'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        item.code = list[i]
        resList.push(item)
      }
      this.industries = resList;
    })
    this.$http.get('/dictionary/selectValue', {params: {key: 'tags'}}).then((res) => {
      console.log(res)
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      this.tagsList = resList;
    })
    this.showExpert()
  },
  name: "",
  components: {EditExpert, AddExperts},
  data() {
    return {
      expertInfo: null,
      expertInfoDisplay: false,
      delShow: false,
      addExperts: false,
      userName: null,
      time: null,
      industry: null,
      industries: [],
      display: false,
      expert: null,
      date: null,
      selectedIndustry: null,
      selectedProducts: null,
      items: [
        {label: '智库推送', to: '/expertManagement'},
        {label: '专家管理', to: '/expertManagement'}
      ],
      product: {},
      filters: {},
      tags: null,
      tagsList: null,

    }
  },
  methods: {
    add() {
      this.addExperts = true
      this.$nextTick(() => {
        this.$refs.addExperts.init()
      })
    },
    closeAdd() {
      this.addExperts = false
      this.showExpert()
    },
    showExpert() {
      console.log(this.tags)
      this.$http.get('/expert/manage', {
        params: {
          name: this.userName,
          time: this.time,
          industry: this.industry,
          tags: this.tags,
        }
      }).then((res) => {
        this.expert = res.data
        console.log(res.data)
      })
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.delShow = true
    },
    deleteUser() {
      console.log(this.product)
      this.$http.get('/expert/closePower', {
        params: {
          'id': this.product.id,
        }
      }).then(() => {
        this.delShow = false;
        this.$toast.add({severity: 'success', summary: '成功', detail: '权限修改成功', life: 3000});
        this.showExpert()
      })
    },
    update(info) {
      this.display = true
      this.$nextTick(() => {
        this.$refs.update.init(info)
      })
    },

    diaLogClose(e) {
      this.display = e
    },

    getExpertInfo(info) {
      this.expertInfoDisplay = true
      this.expertInfo = info
      if (this.expertInfo.pic) {
        this.expertInfo.pic = this.$global_msg.imgurl + this.expertInfo.pic
      }

    }

  }
}
</script>

<style scoped>

</style>
