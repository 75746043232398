<template>
  <Dialog style="text-align: center;width: 50%" v-model:visible="display"
          modal="true"
          @hide="close()">
    <Toast/>
    <template #header>
      <h3>{{ title }}专家</h3>
    </template>
    <div class="p-col-12" @click="eventStop($event)">
      <div class="p-grid p-fluid">
        <div class="p-col-6 ">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>专家姓名：</label>
            <InputText v-model="from.name"/>
          </div>
        </div>
        <div style="text-align: left" class="p-col-6">
          <div class="p-flex-column p-mt-4">
            <label>专业领域：</label>
            <Dropdown v-model="from.industry" :options="industry" optionLabel="name"
                      option-value="name"
                      placeholder="专业领域" display="chip"/>
          </div>
        </div>
        <!--              <div style="text-align: left" class="p-col-12 p-md-6">-->
        <!--                <div class="p-flex-column p-mt-4">-->
        <!--                  <label>行业：</label>-->
        <!--                  <InputText v-model="from.industry"/>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--                <div class="p-col-12 p-md-6">-->
        <!--                    <div style="text-align: left" class="p-flex-column p-mt-4">-->
        <!--                        <label>入驻时间：</label>-->
        <!--                        <Calendar v-model="from.postTime" :showTime="true" :showSeconds="true"  />-->
        <!--                    </div>-->
        <!--                </div>-->
        <div class="p-col-6">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>问题解答次数：</label>
            <InputText v-model="from.questionNum"/>
          </div>
        </div>
        <div class="p-col-6">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>活动参与次数：</label>
            <InputText v-model="from.activityNum"/>
          </div>
        </div>
        <div class="p-col-6">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>共获取报酬：</label>
            <InputText v-model="from.remuneration"/>
          </div>
        </div>
        <div class="p-col-6">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>标签：</label>
            <Chips v-model="from.tags" separator=","/>
          </div>
        </div>
        <div class="p-col-12 ">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>照片：</label>
            <div>
              <FileUpload v-model="from.pic" name="file" :url="fileUploadUrl"
                          @upload="onUpload" @select="selectPic" auto="true" mode="basic" accept="image/*"/>
              <img class="p-mt-2" style="width: 180px" :src="picUrl">
            </div>
          </div>
        </div>
        <div class="p-col-12">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>简介：</label>
            <Textarea v-model="from.brief"/>
          </div>
        </div>
        <div class="p-col-12">
          <div style="text-align: left" class="p-flex-column p-mt-4">
            <label>详情：</label>
            <my-editor :value="from.content" v-on:input="setContent"></my-editor>
          </div>
        </div>
      </div>
    </div>

    <div class="p-grid p-col-12 p-mt-6 p-jc-around">
      <Button label="取消" @click="display=false" class="p-button-outlined"/>
      <Button label="保存" class="p-button-outlined" @click="submit"/>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";

export default {
  name: "",
  components: {myEditor},
  data() {
    return {
      fileUploadUrl: this.$global_msg.frontendUrl + "/file/uploadOne",
      picUrl: null,
      title: false,
      display: false,
      from: {
        id: null,
        name: null,
        industry: null,
        questionNum: null,
        activityNum: null,
        remuneration: null,
        content: null,
        pic: null,
        brief: null,
        tags: null,
      },
      tagList: null,
      industry: []
    }
  },
  mounted() {
    this.$http.get('/dictionary/selectValue', {params: {key: 'zhuanyelingyu'}}).then((res) => {
      let list = res.data.myValue.split(",");
      let resList = []
      for (let i in list) {
        let item = {}
        item.name = list[i]
        resList.push(item)
      }
      this.industry = resList;
    })
  },
  methods: {
    eventStop(e) {
      e.stopPropagation()
    },
    init(info) {
      this.display = true
      if (info != null) {
        this.title = "编辑"
        console.log(info)
        this.from = info
        if (typeof info.tags == 'string') {
          this.from.tags = this.from.tags.split(',')
        }
        console.log(this.from)
      }
    },
    close() {
      this.$emit('close', false)
    },
    submit() {

      this.from.tags = Object.values(this.from.tags).toString()
      this.$http.post('/expert/updateExpert', this.from).then(() => {
        this.$toast.add({severity: 'info', summary: '成功', detail: '操作成功', life: 3000})
        this.display = false
      })
    },
    selectPic(event) {
      this.picUrl = event.files[0].objectURL
    },
    onUpload(event) {

      if (event.xhr.response.indexOf('static') !== -1) {
        this.from.pic = event.xhr.response
        this.$toast.add({severity: 'info', summary: '成功', detail: '文件上传成功', life: 3000})
      } else {
        this.$toast.add({severity: 'error', summary: '失败', detail: '上传失败', life: 3000})
      }
    },
    setContent(val) {
      this.from.content = val
    },
  }
}
</script>

<style scoped>

</style>
